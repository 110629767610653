import React from "react"

type Props = {
  data: GatsbyTypes.IndexBodyQuery
}

const IndexBody: React.FC<Props> = ({data}) => {

  const authorName = data.site?.siteMetadata?.author?.name
  const zenn = data.site?.siteMetadata?.other_blogs?.zenn
  const hatenablog = data.site?.siteMetadata?.other_blogs?.hatenablog

  return (
    <>
    <p>ここは{authorName}が管理するサイトです</p>

    <h3>関連リンク</h3>

    <ul>
      <li><a href={zenn}>Zenn</a></li>
      <li><a href={hatenablog}>はてなブログ</a></li>
    </ul>

    <hr />
    </>
  )
}

export default IndexBody
