import React from 'react'
import Component from './Component'
import { useStaticQuery, graphql } from "gatsby"

const Container: React.FC = () => {
  const data = useStaticQuery<GatsbyTypes.IndexBodyQuery>(graphql`
    query IndexBody {
      site {
        siteMetadata {
          author {
            name
          }
          other_blogs {
            zenn
            hatenablog
          }
        }
      }
    }
  `)

  return (
    <Component data={data} />
  )
}

export default Container
