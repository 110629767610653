import React from "react"
import { graphql, PageProps } from "gatsby"

import Bio from "../components/Bio"
import Layout from "../components/layout"
import SEO from "../components/SEO"
import IndexBody from "../components/IndexBody"

export { Head } from "../functions/Head"

const Index: React.FC<PageProps<GatsbyTypes.IndexQuery>> = ({ data, location }) => {
  const siteTitle = data.site?.siteMetadata?.title || `Title`

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Index" />
      <IndexBody />
      <Bio />
    </Layout>
  )
}

export default Index

export const pageQuery = graphql`
  query Index {
    site {
      siteMetadata {
        title
      }
    }
  }
`
